<template>
<!-- 另存为模板 -->
  <el-dialog
    :title="$t('label.data.exp.savetemp')"
    :visible.sync="isClickSave"
    width="30%"
    :append-to-body="true"
  >
    <el-form ref="form" :model="form" label-width="80px" :rules="rules">
      <!-- 模板名称 -->
      <el-form-item :label="$t('label.emailtemplate.name')" prop="name">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <!-- 可见性 -->
      <el-form-item :label="$t('label.tag.addtags.visibility')">
        <el-select v-model="form.visable">
          <!-- 共有模板 -->
          <el-option :label="$t('vue_label_email_public_template')" value="public"></el-option>
          <!-- 私有模板 -->
          <el-option :label="$t('vue_label_email_private_template')" value="private"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancelSave">
        <!-- 取消 -->
        {{$t('label.cancel')}}
      </el-button>
      <el-button type="primary" @click="submitNewTemplate('form')">
        <!-- 确定 -->
        {{$t('label.confirm')}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import * as Emailobject from "./api.js";
  export default {
    props: {
      value: {
        default: () => {}
      },
      theme: {
        type: String,
        default: ""
      },
      editorText: {
        type: String,
        default: ""
      },
      isClickSave: {
        type: Boolean,
        default: false
      }
    },
    name: "saveNewTemplate",
    data() {
      return {
        form: {
          name: "",
          visable: "private"
        },
        rules: {                                              // '邮件模板名称不能为空',
          name: [{ required: true, message: this.$i18n.t('label.emailobject.template.name.null'), trigger: "blur" }]
        }
      };
    },
    methods: {
      cancelSave() {
          this.$emit('changeShowTag',false);
          this.form.name = '';
          this.form.visable = '';
      },
      submitNewTemplate(formName) {
        this.$refs[formName].validate(valid => {
          if (valid) {
            let options = {
              name: this.form.name,
              subject: this.theme,
              htmlbody: this.editorText,
              visibility: this.form.visable
            };
            this.saveEmailTemplate(options);
          } else {
            return false;
          }
        });
      },
      //保存邮件模板
      async saveEmailTemplate(options) {
        let result = await Emailobject.saveEmailTemplate(options);
        if (result.result == true && result.data) {
          this.$emit('changeShowTag',false);
          this.form.name = '';
          this.form.visable = '';
        }
      }
    },
    watch: {
      form: {
        handler(newVal, oldVal) {
          if (newVal != oldVal) {
            this.$emit("input", newVal);
          }
        },
        immediate: true,
        deep: true
      }
    },
    mounted() {}
  };
</script>

<style lang="scss" scoped>
  .el-button {
    padding: 7px 15px;
  }
 
</style>