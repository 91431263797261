<template>
  <div>
    <el-dialog
      :title="$t('vue_label_email_update_template')"
      :visible.sync="istabimg"
      width="45%"
      :before-close="closeTemplate"
      :append-to-body="true"
    >
      <div class="templatetop">
        <div class="templateseach">
          <el-input v-model="searchvalue" placeholder=""></el-input>
          <div class="seachicon">
            <svg class="icon" aria-hidden="true">
              <use href="#icon-search"></use>
            </svg>
          </div>
        </div>
      </div>
      <div class="templatebot">
        <!-- 全部邮件模板 vue_label_email_all_email_templates-->
        <div class="title">{{ $t("vue_label_email_all_email_templates") }}</div>
        <div class="template-body" v-loading="loading">
          <ul>
            <li
              v-for="(item, index) in filterList"
              :key="index"
              :class="isSelectedIndex == index ? 'active' : ''"
              @click="selectTemplate(index, item)"
            >
              <span>{{ item.name }}</span>
            </li>
          </ul>
          <div class="no-data" v-show="filterList.length <= 0">
            <!-- 没有数据 -->
            {{ $t("label.productsales.nodata") }}
          </div>
        </div>
      </div>
      <div
        class="templateope"
        @mouseover="overEdict"
        @mouseout="outEdict"
        @click="edictEmailTemplate"
      >
        <svg class="icon" aria-hidden="true">
          <use :href="edictIsselect ? edictBlue : edictGrey"></use>
        </svg>
        <span :style="{ color: edictIsselect ? '#006DCC' : '' }">
          <!-- 管理邮件模板 -->
          {{ $t("label.emailobject.manage.template") }}
        </span>
      </div>
      <div>
        <!-- 管理邮件模板 -->
        <el-dialog
          :title="$t('label.emailobject.manage.template')"
          :visible.sync="isClickedictTemp"
          width="45%"
          :append-to-body="true"
        >
          <div>
            <emailTemplate @insertMyTemplate="insertMyTemplate"></emailTemplate>
          </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelDia">
              <!-- 取消 -->
              {{ $t("label.cancel") }}
            </el-button>
            <el-button type="primary" @click="cancelDia">
              <!-- 确定 -->
              {{ $t("label.confirm") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
      <div>
        <!-- 无法保存模板 -->
        <el-dialog
          :title="$t('vue_label_email_Could_not_save_template')"
          :visible.sync="isHasNotTheme"
          width="30%"
          :append-to-body="true"
        >
          <!-- 请先添加主题后再另存为模板 -->
          {{ $t("vue_label_email_please_add_a_theme") }}
          <span slot="footer" class="dialog-footer">
            <el-button @click="hiddenT">
              <!-- 取消 -->
              {{ $t("label.cancel") }}
            </el-button>
            <el-button type="primary" @click="hiddenT">
              <!-- 确定 -->
              {{ $t("label.confirm") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
      <div>
        <!-- 提示 -->
        <el-dialog
          :title="$t('label.prompt')"
          :visible.sync="isInsertTem"
          width="30%"
          :append-to-body="true"
        >
          <div class="englishWrap">
            <!-- 插入此模板将覆盖当前邮件主题 -->
            {{ $t("vue_label_email_current_theme") }}
          </div>
          <!-- {{$t('vue_label_email_current_email')}} -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="cancelInsert">
              <!-- 取消 -->
              {{ $t("label.cancel") }}
            </el-button>
            <el-button type="primary" @click="insertTemplate">
              <!-- 确定 -->
              {{ $t("label.confirm") }}
            </el-button>
          </span>
        </el-dialog>
      </div>
      <div>
        <save-new-template
          v-model="templateData"
          :theme="theme"
          :editorText="editorText"
          :isClickSave="isClickSave"
          v-if="isClickSave"
          @changeShowTag="changeShowTag"
        ></save-new-template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeTemplate">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>



<script>
import emailTemplate from "./emailTemplate";
import saveNewTemplate from "./saveNewTemplate";
import * as Emailobject from "./api.js";

export default {
  props: {
    theme: {
      type: String,
      default: "",
    },
    editorText: {
      type: String,
      default: "",
    },
    istabimg: {
      default: false,
      type: Boolean,
    },
  },
  name: "updateTemplate",
  data() {
    return {
      templateDetail: {},
      //插入新模板
      isInsertTem: false,
      //保存为新模板获取的数据
      templateData: {},
      loading: true,
      checked: false,
      searchvalue: "",
      //当前选中模板的下标
      isSelectedIndex: null,
      emailTemplateArr: [],
      //添加新模板高亮
      addIsselect: false,
      edictIsselect: false,
      edictBlue: "#icon-editorblue",
      edictGrey: "#icon-editor",
      //显示管理邮件模板弹框
      isClickedictTemp: false,
      //显示另存为弹框
      isClickSave: false,
      //显示无法保存模板弹框
      isHasNotTheme: false,
    };
  },
  components: {
    emailTemplate,
    saveNewTemplate,
  },
  methods: {
    //插入模板
    insertTemplate() {
      this.applicationSubject(this.templateDetail);
      this.isInsertTem = false;
      this.isClickedictTemp = false;
    },
    cancelInsert() {
      this.isInsertTem = false;
    },
    changeShowTag() {
      this.isClickSave = false;
      this.getEmailTemplate("false", "");
    },
    hiddenT() {
      this.isHasNotTheme = false;
    },
    //另存为新模板
    saveTemplate() {
      if (this.theme == "") {
        this.isHasNotTheme = true;
        return false;
      }
      this.isClickSave = true;
    },
    //获取邮件模板
    async getEmailTemplate(onlyShowMyTemplate, searchWord) {
      let result = await Emailobject.getEmailTemplate(
        onlyShowMyTemplate,
        searchWord
      );
      if (result.result == true && result.returnCode == 1) {
        this.loading = false;
        this.emailTemplateArr = result.data;
      }
    },
    //应用主题
    async applicationSubject(item) {
      let option = {
        templateId: item.id,
      };
      let result = await Emailobject.getTemplateSubject(option);
      if (result.result == true && result.data) {
        if (!result.data.content) {
          result.data.content = "";
        }
        this.$Bus.$emit("deliver-new-template", result.data);
      }
    },
    //插入我的模板
    insertMyTemplate(item) {
      this.isInsertTem = true;
      this.templateDetail = item;
    },
    //管理邮件模板
    edictEmailTemplate() {
      //   this.$Bus.$emit('hiddle-template',false);
      this.isClickedictTemp = true;
    },
    //选择模板
    selectTemplate(index, item) {
      this.isSelectedIndex = index;
      this.isInsertTem = true;
      this.templateDetail = item;
    },
    //添加新模板移入移出
    overAdd() {
      this.addIsselect = true;
    },
    outAdd() {
      this.addIsselect = false;
    },
    //管理模板移入移出
    overEdict() {
      this.edictIsselect = true;
    },
    outEdict() {
      this.edictIsselect = false;
    },
    cancelDia() {
      this.isClickedictTemp = false;
    },
    cancelSave() {
      this.isClickSave = false;
    },
    //隐藏模板弹框
    closeTemplate() {
      this.$emit("closeTemplate");
    },
  },
  mounted() {
    this.getEmailTemplate("false", "");
  },
  computed: {
    //筛选出的模板列表
    filterList() {
      if (!this.searchvalue) return this.emailTemplateArr;
      return this.emailTemplateArr.filter((res) => {
        return new RegExp(this.searchvalue).test(res.name);
      });
    },
  },
};
</script>


<style lang="scss" scoped>
::v-deep .el-input__inner {
  height: 30px;
}
.templatetop {
  display: flex;
  align-items: flex-end;
  .templateseach {
    width: 100%;
    position: relative;
    .seachicon {
      width: 15px;
      height: 15px;
      position: absolute;
      right: 6px;
      top: 7px;
      cursor: pointer;
      .icon {
        width: 100%;
        height: 100%;
      }
    }
    ::v-deep .el-input__inner {
      padding-right: 33px;
    }
  }
}
.templatebot {
  width: 100%;
  margin-top: 10px;
  border: 1px solid #d8d8d8;
  max-height: 348px;
  padding: 10px 0;
  height: 283px;

  .title {
    font-size: 14px;
    color: #666;
    margin-left: 20px;
  }
  .template-body {
    overflow: auto;
    height: 250px;
    .no-data {
      width: 100%;
      text-align: center;
    }
  }
  ul {
    .active {
      background: #f3f2f2;
    }
    li {
      span {
        margin-left: 30px;
        display: inline-block;
        width: calc(100% - 30px);
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #080707;
      height: 30px;
      cursor: pointer;
    }
  }
}
.templateope {
  cursor: pointer;
  margin-top: 14px;
  height: 15px;
  line-height: 15px;
  .icon {
    width: 14px;
    height: 14px;
    margin-right: 10px;
  }
  span {
    color: #080707;
  }
}
.englishWrap {
  word-wrap: break-word;
  word-break: normal;
}
</style>