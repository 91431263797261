<template>
  <div>
    <div class="templatetop">
      <div class="templateseach">
        <el-input v-model="searchvalue" placeholder=""></el-input>
        <div class="seachicon">
          <svg class="icon" aria-hidden="true">
                 <use href="#icon-search"></use>
           </svg>
        </div>
      </div>
      <div>
        <el-checkbox v-model="checked" @change="getOwnerTemplate"
          >
          <!-- 仅显示我的模版 -->
          {{$t('label.emailobject.my.template')}}
        </el-checkbox
        >
      </div>
    </div>
    <div class="templatebot" v-loading="loading">
      <ul>
        <li
          v-for="(item, index) in filterList"
          :key="index"
          :class="isSelectedIndex == index ? 'active' : ''"
          @mouseover="overAdd(index)"
          @mouseout="outAdd(index)"
        >
          <span class="name" @click="insertMyTemplate(item)">{{ item.name }}</span>
        </li>
      </ul>
      <div class="no-data" v-show="filterList.length <= 0">
        <!-- '没有数据' -->
        {{$t('label.productsales.nodata')}}
      </div>
    </div>
  </div>
</template>



<script>
  import * as Emailobject from "./api.js";

  export default {
    data() {
      return {
        loading: true,
        checked: false,
        searchvalue: "",
        isSelectedIndex: null,
        templateArr: []
      };
    },
    methods: {
      //插入我的模板
      insertMyTemplate(item){
        
        this.$emit('insertMyTemplate',item);
        
      },
      //置顶模板
      toTopTemplate(id) {
        let index = this.templateArr.findIndex(res => res.id == id);
        this.templateArr.unshift(this.templateArr.splice(index, 1)[0]);
        this.toTopTemplateRequest({ id: id });
        
      },
      async toTopTemplateRequest(options) {
        let result = await Emailobject.toTopTemplate(options);
        
      },
      //选择自己的模板
      getOwnerTemplate(val) {
        if (this.checked == true) {
          this.getEmailTemplate("true", "");
        } else if (this.checked == false) {
          this.getEmailTemplate("false", "");
        }
      },
      //获取邮件模板
      async getEmailTemplate(onlyShowMyTemplate, searchWord) {
        let result = await Emailobject.getEmailTemplate(
          onlyShowMyTemplate,
          searchWord
        );
        if (result.result == true && result.returnCode == 1) {
          this.loading = false;
          this.templateArr = result.data;
        }
      },
      overAdd(index) {
        this.isSelectedIndex = index;
      },
      outAdd(index) {
        this.isSelectedIndex = null;
      }
    },
    mounted() {
      this.getEmailTemplate("false", "");
    },
    computed: {
      //筛选出的模板列表
      filterList() {
        if (!this.searchvalue) return this.templateArr;
        return this.templateArr.filter(res => {
          return new RegExp(this.searchvalue).test(res.name);
        });
      }
    }
  };
</script>


<style lang="scss" scoped>
  ::v-deep .el-input__inner {
    height: 30px;
  }
  .templatetop {
    display: flex;
    align-items: flex-end;
    .templateseach {
      width: 100%;
      position: relative;
      margin-right: 10px;
      .seachicon {
        width: 15px;
        height: 15px;
        position: absolute;
        right: 6px;
        top: 8px;
        cursor: pointer;
        .icon {
          width: 100%;
          height: 100%;
        }
      }
      ::v-deep .el-input__inner {
        padding-right: 33px;
      }
    }
  }
  .templatebot {
    width: 100%;
    margin-top: 10px;
    border: 1px solid #d8d8d8;
    max-height: 348px;
    padding: 10px 0;
    height: 348px;
    overflow: auto;
    .no-data {
      width: 100%;
      text-align: center;
    }
    ul {
      .active {
        background: #f3f2f2;
      }
      overflow: auto;
      li {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 30px;
        color: #080707;
        .name {
          margin-left: 10px;
          display: inline-block;
          width: calc(100% - 30px);
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          cursor: pointer;
        }
        .img {
          margin-right: 10px;
          cursor: pointer;
          img {
            width: 14px;
            height: 14px;
          }
        }
      }
    }
  }
</style>