import axios from '@/config/httpConfig'

// 获取收件人信息
export function getTextMessageGetShouJianRenData(data) {
    return axios.post('/textMessage/getShouJianRenData', data)
}

// 发短信
export function getTextMessageSendTextMessage(data) {
    return axios.post('/textMessage/sendTextMessage', data)
}
// 获取视图列表
export function GetViewList(data) {
    return axios.post('/view/list/getViewList', data)
}
// 查询短信模板   
export function getTextMessageGetTextMessageTemplata(data) {
    return axios.post('/textMessage/getTextMessageTemplate', data)
}

// 搜索手机号   
export function getTextMessageGetTextSearchPhone(data) {
    return axios.post('/textMessage/searchPhone', data)
}
// 短信已读未读
export function getChangeRead(data) {
    return axios.post('/textMessage/changeRead', data)
}
// 获取邮件模板列表
export function getMergeMailList(data) {
    return axios.post('/mailMerge/getMergeMailList', data)
}
// 获取邮件合并页面信息
export function getMailMergePageInfo(data) {
    return axios.post('/mailMerge/getMailMergePageInfo', data)
}
// 合并文档
export function generateDocument(data) {
    return axios.post('/mailMerge/generateDocument', data)
}
//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}
// 查询短信列表
export function getListContacts(data) {
    return axios.post('/textMessage/getTextMessageList', data)
}

// 获取下级用户列表
export function getSubordinateUserList(data) {
    return axios.post('/user/getSubUserList', data)
}

// 获取视图列表表头
export function getViewListHeader(data) {
    return axios.post('/view/list/getViewListHeader', data)
}

// 获取视图列表记录
export function getViewListData(data) {
    return axios.post('/view/list/getViewListAjax', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}