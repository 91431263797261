<template>
  <!-- 添加联系人组件弹框 -->
  <div class="addContacts">
    <!-- 添加联系人 -->
    <el-dialog
      :visible.sync="addCampaignMembersDialog"
      top="4%"
      :title="$t('vue_label_SMS_addcontact')"
      width="80%"
      append-to-body
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      :before-close="closeAddCampaignMembersDialog"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane :label="$t('label.brief.lead')" name="lead"></el-tab-pane>
        <el-tab-pane
          :label="$t('label_tabpage_contactsz')"
          name="contact"
        ></el-tab-pane>
      </el-tabs>
      <div style="margin-bottom: 10px">
        <span style="font-size: 14px; margin-right: 10px">
          <!-- 视图筛选 -->
          {{$t("vue_label_norm_viewfilter")}}</span>
        <el-select
          v-model="viewId"
          :placeholder="$t('label_emailtocloudcc_select1')"
        >
          <el-option
            v-for="item in views"
            :key="item.label"
            :label="item.label"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </div>
      <!-- 列表 -->
      <table-panel
        class="text-center"
        ref="tablePanel"
        :border="true"
        :table-height="tableHeight"
        :show-index="true"
        :checked="true"
        :show-table-head-tool="false"
        :table-attr="tableAttr"
        :page-obj="pageObj"
        :noDataTip="noDataTip"
        :pict-loading="pictLoading"
        :use-hyperlinks='false'
        @locked="locked"
        @append="append"
        @onchange="onchange"
      >
      </table-panel>
      <div style="font-size: 14px; margin-top: 8px">
        <!--已选择  -->
        {{ $t("vue_label_SMS_chosen")
        }}{{ ($refs.tablePanel && $refs.tablePanel.checkedList.length) || 0 }}
        <!-- 潜在客户 联系人-->
        {{
          activeName === "lead"
            ? $t("label.brief.lead")
            : $t("label_tabpage_contactsz")
        }}
      </div>
      <div style="margin-top: 10px; text-align: right" slot="footer">
        <el-button size="mini" @click="closeAddCampaignMembersDialog">{{
          $t("button_partner_cancel")
        }}</el-button>
        <el-button size="mini" type="primary" @click="next">{{
          $t("UG_Forcast_09")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { TablePanel } from "@/components/index.js";
import * as CommonObjApi from "../api";

export default {
  props: {
    showAddCampaignMembers: {
      type: Boolean,
      default: false,
    },
    bigTitle: {
      type: String,
      default: "",
    },
  },
  components: {
    TablePanel,
  },
  data() {
    return {
      campaignId: this.$route.params.id,
      viewId: "",
      views: [],
      status: "",
      statusList: [],
      activeName: "lead",
      tableHeight: 400,
      tableAttr: null,
      pageObj: {
        dataList: [],
      },
      noDataTip: false,
      pictLoading: true,
      addCampaignMembersDialog: this.showAddCampaignMembers,
      add2CampaignDialog: false,
      marketingActivitiesName: "",
      pageNum: 1,
      pageSize: 20,
      sortField: "", // 排序字段
      sortFieldName: "",
      sortDir: "asc", // 排序方式
      searchKeyWord: "", // 搜索关键字
      conditionValues: "",
      tagIds: [], // 搜索标签
      isAllTag: "", // 是否同时满足所有标签
      isallsearch: "false",
      myself: "false",
      scrollId: "false",
      ids: [],
      type: "",
      dataList: [],
      arr: [],
      // 选中状态的数组
      selectedStatus:[],
    };
  },
  created() {
    this.activeName = "lead";
    this.getViewList();
  },
  methods: {
    // 勾选框选中事件
    onchange(val){
      
     this.selectedStatus=val;
      
    },
    getViewList() {
      CommonObjApi.GetViewList({ objId: this.activeName })
        .then((res) => {
          this.views = res.data;
          this.viewId = res.data[0].id;
          this.pageNum = 1;
          this.pageObj.dataList = [];
          this.$refs.tablePanel.checkedList = [];
          this.getViewListHeader();
          this.getViewListData();
        })
        .catch(() => {});
    },
    // 获取视图列表表头
    getViewListHeader() {
      this.pictLoading = true;
      this.tableAttr = [];

      CommonObjApi.getViewListHeader({
        obj: this.activeName === "lead" ? "004" : "003",
        viewId: this.viewId,
      }).then((res) => {
        this.tableAttr = res.data.headLabel;
        this.tableAttr.forEach((item, idx) => {
          this.$set(this.tableAttr[idx], "edit", false);

          // 名称、查找、主详、查找多选均可跳转
          if (
            item.schemefieldName === "name" ||
            item.schemefieldType === "Y" ||
            item.schemefieldType === "M" ||
            item.schemefieldType === "MR"
          ) {
            this.$set(this.tableAttr[idx], "click", "detail");
          }

          this.$set(this.tableAttr[idx], "sortByThis", false);
          this.$set(this.tableAttr[idx], "sortDir", "asc");
          this.$set(this.tableAttr[idx], "fixed", false);
          this.$set(this.tableAttr[idx], "locked", false);
          this.$set(this.tableAttr[idx], "modify", false);
        });

        this.pictLoading = false;
        this.$refs.tablePanel &&
          this.$refs.tablePanel.changeTableAttr(this.tableAttr);
      });
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getViewListData();
      }
    },
    // 获取视图数据
    getViewListData() {
      /**
       * 参数说明
       * 参数名	必选	类型	说明
       * obj	是	string	对象前缀
       * viewId	是	string	视图Id
       * pageNum	是	string	页数
       * pageSize	是	string	每页数量
       * sortField	是	string	排序字段apiname
       * sortDir	是	string	排序类型(desc[降序]/asc[升序])
       * searchKeyWord	是	string	搜索关键字
       * conditionValues	是	string	高级搜索条件
       * ischangenum	是	string	是否修改每页查询数目（修改的数目为视图默认每页数量）
       * recordnums	是	string	每页查询默认数目
       * tagIds	否	string	标签id，多个标签逗号分隔，按选中标签查询
       * isAllTag	否	string	是否同时满足所有选中标签（true/false）
       * campaignid	否	string	市场活动成员是否只查询现有成员
       * isallsearch	否	string	是否启用启用全局搜索，conditionValues不用空也不启用全局搜索
       * myself	否	string	全局搜索是否只查询所有人是自己的记录
       * scrollId	否	string	全文检索查询返回值，下一页查询回传
       * type	否	string	contact/lead特殊查询使用以不使用全文检索，参数暂时不用
       */

      this.noDataTip = false;
      this.pictLoading = true;
      if (this.pageNum === 1) {
        this.pageObj.dataList = [];
      }
      this.getViewListDataParams = {
        obj: this.activeName === "lead" ? "004" : "003",
        viewId: this.viewId,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        sortField: this.sortField,
        sortDir: this.sortField === "" ? "" : this.sortDir,
        searchKeyWord: this.searchKeyWord,
        conditionValues: this.conditionValues,
        ischangenum: "false",
        recordnums: this.pageSize,
        tagIds: this.tagIds.toString(),
        isAllTag: this.isAllTag,
        campaignid: "false",
        isallsearch: this.isallsearch,
        myself: this.myself,
        scrollId: this.scrollId,
        type: "",
      };
      CommonObjApi.getViewListData(this.getViewListDataParams).then((res) => {
        this.totalSize = res.data.totalSize;

        if (res.data.list.length === 0 && this.pageNum === 1) {
          this.pageObj.dataList = [];
        } else if (this.pageObj.dataList.length === 0 || this.pageNum === 1) {
          this.pageObj.dataList = res.data.list;
          this.pageNum++;
        } else {
          let checkedList = this.$refs.tablePanel.getCheckedList();
          this.pageObj.dataList = [...this.pageObj.dataList, ...res.data.list];
          this.pageObj.dataList.forEach((element) => {
            checkedList.forEach((item) => {
              if (item.id === element.id) {
                element.checked = true;
              }
            });
          });

          this.pageNum++;
          this.$refs.tablePanel.dataList = this.pageObj.dataList;
        }
        // 判断是否有更多数据
        if (res.data.haveMore === "false") {
          this.noDataTip = true;
        }

        this.pictLoading = false;
        this.lastRefresh = new Date();
        this.pageObj.totalSize = res.data.totalSize;
      });
    },
    // 去重
    // duplicateRemoval(){
    //   this.arr = this.$refs.tablePanel.checkedList;
    //     for (var i = 0; i < arr.length; i++) {
    //     for (var j = i + 1; j < arr.length; j++) {
    //       if (arr[i].id == arr[j].id) {
    //         arr.splice(j, 1);
    //       }
    //     }
    //   }
    //   return this.arr;
    // },
    // 下一步
    next() {
      // this.duplicateRemoval()
       
      if (this.$refs.tablePanel.checkedList.length > 0) {
        // let ids = this.$refs.tablePanel.checkedList.map(res => res.id).join();
        // let name = this.$refs.tablePanel.checkedList.map(res => res.name).join();
        // let option = {
        //   ids: ids,
        //   name:name,
        //   type: this.activeName
        // };
        //
        this.$emit("next", this.$refs.tablePanel.checkedList, this.activeName);
        this.$refs.tablePanel.checkedList.forEach(() => {});
      } else {
        let name =
          this.activeName === "lead"
            ? this.$i18n.t("label.brief.lead")
            : this.$i18n.t("label_tabpage_contactsz");
        this.$message.warning(
          // 请您选择要添加的
          this.$i18n.t("vue_label_SMS_selectadd", { name: name })
        );
      }
    },
    getSelectValue() {
      CommonObjApi.getSelectValue({ fieldId: "ffe201200108912H6Zr0" }).then(
        (res) => {
          this.statusList = res.data;
        }
      );
    },

    // 锁定
    // eslint-disable-next-line no-unused-vars
    locked(field, flag) {
      let fiexdList = this.tableAttr.filter((item) => {
        return item.fixed === true;
      });

      if (fiexdList.length > 2) {
        // 最多只能固定三列
        // eslint-disable-next-line no-undef
        Message.warning(this.$i18n.t('vue_label_commonobjects_view_fixed_at_most'));
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.fixed = false;
            item.locked = false;
          }
        });
      } else {
        this.tableAttr.forEach((item) => {
          if (item.nameLabel === field.nameLabel) {
            item.fixed = !item.fixed;
          }
        });
      }

      this.$forceUpdate();
      this.$refs.tablePanel.changeTableAttr(this.tableAttr);
    },
    // 取消弹框
    closeAddCampaignMembersDialog() {
      //  this.$nextTick(()=>{
      //  this.$refs.tablePanel.echso()
      // })
      this.$emit("closeAddCampaignMembersDialog");
    },
    closeAdd2CampaignDialog() {
      this.add2CampaignDialog = false;
    },
    echos(val) {
      this.$nextTick(() => {
        this.$refs.tablePanel.echo(val);
      });
    },
  },
  watch: {
    activeName() {
      this.getViewList();
    },
    viewId(nval, oval) {
      if (this.showAddCampaignMembers && oval !== "") {
        this.pageNum = 1;
        this.pageObj.dataList = [];
        this.$refs.tablePanel.checkedList = [];
        this.getViewListHeader();
        this.getViewListData();
      }
    },
    showAddCampaignMembers(nval) {
      this.addCampaignMembersDialog = nval;
      this.getViewList();
    },
  },
};
</script>

<style lang="scss" scoped>
.addContacts {
  ::v-deep .el-dialog {
    padding: 10px;
  }
}
.red {
  color: red;
}

.labelBox {
  font-size: 14px;
  color: #666;
}

.marketingActivitiesName {
  width: 100%;
  height: 30px;
  line-height: 30px;
  border: 1px solid #e3e2e2;
  border-radius: 3px;
}

::v-deep .el-tabs__item {
  font-size: 15px;
}

::v-deep .el-select .el-input__inner {
  height: 30px;
  line-height: 30px;
}

::v-deep .el-input__icon {
  line-height: 30px;
}

</style>